<template>
  <div class="photogallery d-flex flex-column align-items-center common-bg">
    <div class="container-lg">
      <div class="d-flex pt-5 mb-3 justify-content-center">
        <img
          src="@/assets/img/jpmorganlogowhite.svg"
          class="img-fluid"
          alt=""
        />
      </div>

      <div class="d-flex btn-nav justify-content-between my-5">
        <router-link
          to="/lobby"
          class="btn btn-outline-light btn-sm text-uppercase"
          >Back to Lobby</router-link
        >
        <button
          class="btn btn-primary btn-sm text-uppercase"
          data-toggle="modal"
          data-target="#privacyModal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-pencil-square"
            viewBox="0 0 16 16"
          >
            <path
              d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
            />
            <path
              fill-rule="evenodd"
              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
            />
          </svg>
          Submit Photo
        </button>
      </div>
      <div class="row mb-3">
        <div class="col-8 mx-auto">
          <div class="msg-card card card-body border-primary shadow">
            <blockquote class="blockquote">
              <p class="mb-0 font-weight-bold">Rules:</p>
              <ul style="font-size: 0.9rem">
                <li>
                  Your photo and photo description should reflect how we embrace DEI through this year’s theme “You Belong Here: Diverse. Inclusive. Together.”
                </li>

                <li>
                  The photo contest will remain open from May 22 to 31, 2023.
                </li>
                <li>Only one photo per employee is allowed.</li>
                <li>
                  Kindly limit the file size of the photo to a maximum of 5MB in JPEG or PNG format only.
                </li>
              </ul>
              <p class="mb-0 font-weight-bold">Vote for your favorite photo:</p>
              <ul style="font-size: 0.9rem">
                <li>Voting period: May 22 – June 9, 2023.</li>
                <li>
                  The photo with the most number of likes will be the winner(s) and the results will be announced in the upcoming edition of our APAC DEI newsletter.
                </li>
              </ul>
            </blockquote>
          </div>
        </div>
      </div>
      <div class="row py-3">
        <div
          class="col-3 mb-3 d-flex flex-column justify-content-between"
          v-for="(img, index) in gallery"
          :key="index"
        >
          <img
            :src="`${link}${img.image}`"
            alt="dummy_img.jpg"
            @click="showSingle(`${link}${img.image}`, img.description)"
            class="img-fluid pointer"
          />
          <div class="py-3">
            <p class="text-light">{{ img.description }}</p>
            <div class="d-flex justify-content-between align-items-center">
              <span class="text-light font-weight-bold"
                >Likes {{ img.likes }}</span
              >
              <!-- use arr.includes for if liked -->
              <svg
                v-if="!likes.includes(img.id)"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="#fff"
                class="bi bi-hand-thumbs-up pointer"
                viewBox="0 0 16 16"
                @click="likeImage(img.id,index)"
              >
                <path
                  d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"
                />
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="#fff"
                class="bi bi-hand-thumbs-up-fill pointer"
                viewBox="0 0 16 16"
                @click="unlikeImage(img.id,index)"
              >
                <path
                  d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <!-- all props & events -->
      <vue-easy-lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
      ></vue-easy-lightbox>
    </div>

    <div
      class="modal fade"
      id="privacyModal"
      tabindex="-1"
      aria-labelledby="privacyLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                  />
                </svg>
              </span>
            </button>

            <h3 class="font-weight-bold mb-3">Privacy Statement</h3>
            <p>
              <strong>*By clicking “Agree”</strong> and submitting a photo, you
              agree to the following:
            </p>
            <p>
              <strong>You represent</strong> that you own all copyrights and
              other intellectual property rights in the photograph, and that you
              have been given the authority by each individual depicted or
              represented in the photograph to bind such individual to these
              release terms.
            </p>
            <p>
              <strong>You grant</strong> JPMorgan Chase & Co. the right, in its
              sole discretion, to print, publish, broadcast or otherwise use the
              photograph submitted by you in any manner, in any medium now known
              or hereafter developed, including without limitation, on the
              JPMorgan Chase & Co. Intranet. By submitting a photograph, you
              consent and have procured the consent of any other individual
              depicted in the photograph, to such publication or any other use.
              You further consent to the use of your name and any description
              included with the submission.
            </p>
            <p>
              <strong>As a condition</strong> of submitting your photograph you
              unconditionally and irrevocably waive, and undertake that any
              other individual depicted in a photograph has waived, all claims
              to compensation for use of the photograph, and/or any rights with
              respect to such use you may have under copyright law, the right to
              publicity, the right to privacy, the law of defamation, and any
              other common law or statutory claims under the laws of any
              jurisdiction.
            </p>

            <button class="btn btn-primary btn-block" @click="postPhotoModal">
              Agree
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- write message modal -->
    <div
      class="modal fade"
      id="writeMessageModal"
      tabindex="-1"
      aria-labelledby="writeMessageModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                  />
                </svg>
              </span>
            </button>

            <h3 class="font-weight-bold mb-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                class="bi bi-chat-left-quote"
                viewBox="0 0 16 16"
              >
                <path
                  d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                />
                <path
                  d="M7.066 4.76A1.665 1.665 0 0 0 4 5.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 1 0 .6.58c1.486-1.54 1.293-3.214.682-4.112zm4 0A1.665 1.665 0 0 0 8 5.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 1 0 .6.58c1.486-1.54 1.293-3.214.682-4.112z"
                />
              </svg>
              Photo
            </h3>
            <small class="form-text text-body mb-4">
              Please type your message below
            </small>
            <div class="form-group">
              <label for="sid">SID</label>
              <input
                type="text"
                class="form-control"
                placeholder="SID"
                name="sid"
                id="sid"
                v-model="sid"
              />
              <span
                class="ms-3 text-danger"
                v-for="message of validation_message.sid"
                :key="`sid-${message}`"
                >{{ message }}<br
              /></span>
            </div>
            <div class="form-group">
              <label for="desc"
                >Brief Description - in 25 characters or less only</label
              >
              <input
                type="text"
                class="form-control"
                placeholder=""
                name="desc"
                id="desc"
                v-model="description"
              />
              <span
                class="ms-3 text-danger"
                v-for="message of validation_message.description"
                :key="`description-${message}`"
                >{{ message }}<br
              /></span>
            </div>
            <div class="form-group">
              <label for="formFile">Photo:</label>
              <input
                type="file"
                class="form-control-file"
                id="formFile"
                accept="image/*"
                @change="selectfile"
              />
              <span
                class="ms-3 text-danger"
                v-for="message of validation_message.image"
                :key="`image-${message}`"
                >{{ message }}<br
              /></span>
            </div>

            <button class="btn btn-primary btn-block" @click="postImage">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/assets/js/Api";

import $ from "jquery";
import VueEasyLightbox from "vue-easy-lightbox";
export default {
  name: "photogallery",
  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      message: "",
      name: localStorage.getItem("participant_name"),
      imgs: [], // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
      file: "",
      sid: "",
      userId: "",
      description: "",
      gallery: [],
      validation_message: {
        sid: [],
        description: [],
        image: [],
      },
      validation_message_gallery: [],
      link: "https://klobbi-jpm.klobbi.com/images/",
      likes: [],
    };
  },
  methods: {
    selectfile(e) {
      const file = e.target.files[0];
      this.file = file;
    },
    generateRandomHash() {
      // Generate an array of random values
      const array = new Uint8Array(32); // 32 bytes = 256 bits
      crypto.getRandomValues(array);

      // Convert the array to a hexadecimal string
      const hash = Array.from(array)
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");

      return hash;
    },
    postImage() {
      console.log(
        "this.description",
        this.description,
        this.description.length,
        this.description.length > 25
      );
      if (this.description.length > 25) {
        this.validation_message.description = [
          "You have reached the maximum number of characters(25).",
        ];
        return;
      }

      this.resetValidation();
      let formData = new FormData();
      formData.append("image", this.file);
      formData.append("sid", this.sid);
      formData.append("description", this.description);

      Api.post("gallery_image", formData).then((res) => {
        if (res.data.error) {
          this.validation_message = res.data.validation_message;
        } else {
          console.log("res", res.data);
          this.resetValidation();
          $("#writeMessageModal").modal("hide");
          this.getImages();
        }
      });
    },
    resetValidation() {
      this.validation_message = {
        sid: [],
        description: [],
        image: [],
      };
    },
    getImages() {
      Api.get("gallery_show")
        .then((res) => {
          this.validation_message_gallery = [];
          this.gallery = res.data.gallery;
        })
        .catch((err) => {
          this.validation_message_gallery.push(err);
        });
    },
    getLikes() {
      Api.get(`get_images?id=${this.userId}`)
        .then((res) => {
          console.log("res",res.data);
          if (res.data.error) {
            this.likes = [];
          } else {
            // console.log("JSON.parse() ", JSON.parse(res.data.image_like));
            console.log("res.data.image_like",JSON.parse(res.data.image_like.image_like));
            this.likes = JSON.parse(res.data.image_like.image_like);
            console.log("this.likes",typeof(this.likes));
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    likeImage(id,keyIndex) {
      this.likes.push(id);
      this.gallery[keyIndex].likes++;
      this.postLikes()

    },
    unlikeImage(id,keyIndex) {
      const index = this.likes.indexOf(id);
      if (index > -1) {
        // only splice array when item is found
        this.likes.splice(index, 1); // 2nd parameter means remove one item only
        this.gallery[keyIndex].likes--;
        this.postLikes()
      }

    },

    postLikes() {
      console.log('here',typeof(`[${this.likes.toString()}]`));
      Api.post(`images`,{id:this.userId,image_like:`[${this.likes.toString()}]`})
        .then((res) => {
         console.log(res.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    postPhotoModal() {
      $("#privacyModal").modal("hide");
      $("#writeMessageModal").modal("show");
    },
    showSingle(img, desc) {
      this.imgs = [
        {
          src: img,
          title: desc,
        },
      ];

      console.log("this.imgs", this.imgs);
      this.show();
    },

    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
  mounted() {
   
    this.getImages();
    (async () => {
      const userId = await localStorage.getItem("JPM_USER_ID");
      console.log("userId", userId);
      if (!userId) {
        const randomHash = this.generateRandomHash();
        // console.log("randomHash", randomHash);
        this.userId = randomHash;
        await localStorage.setItem("JPM_USER_ID", randomHash);
      } else {
        this.userId = userId;
      }
      this.getLikes();
    })().catch((err) => {
      console.error(err);
    });
  },
};
</script>

<style lang="scss">
.photogallery {
  .pointer {
    cursor: pointer;
  }
  & .msg-card {
    cursor: pointer;
    border-width: 5px;

    p {
      font-size: 14px;
    }
    .blockquote-footer {
      font-size: 14px;
    }
  }
  .vel-img-title {
    overflow: visible !important;
    background: #503628b5 !important;
    color: #fff !important;
    font-size: 28px !important;
    opacity: 1 !important;
  }
  @media only screen and (max-width: 900px) {
    .vel-img {
      max-height: 50vh !important;
    }
  }
}
</style>
